import { SVGProps } from "react";

export const Users = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_6946_42348)">
        <path
          d="M14.1663 18V16.3333C14.1663 15.4493 13.8152 14.6014 13.19 13.9763C12.5649 13.3512 11.7171 13 10.833 13H4.16634C3.28229 13 2.43444 13.3512 1.80932 13.9763C1.1842 14.6014 0.833008 15.4493 0.833008 16.3333V18M19.1663 18.0007V16.334C19.1658 15.5955 18.92 14.878 18.4675 14.2943C18.015 13.7106 17.3814 13.2937 16.6663 13.109M13.333 3.10905C14.05 3.29263 14.6855 3.70963 15.1394 4.29431C15.5932 4.87898 15.8395 5.59807 15.8395 6.33822C15.8395 7.07836 15.5932 7.79745 15.1394 8.38212C14.6855 8.9668 14.05 9.3838 13.333 9.56738M10.833 6.33333C10.833 8.17428 9.34062 9.66667 7.49967 9.66667C5.65872 9.66667 4.16634 8.17428 4.16634 6.33333C4.16634 4.49238 5.65872 3 7.49967 3C9.34062 3 10.833 4.49238 10.833 6.33333Z"
          stroke="#888888"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6946_42348">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
