import type { SVGProps } from "react";

export const Mic2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.2715 8.58975L15.105 13.4233L5.00376 22.0444L1.65039 18.691L10.2715 8.58975ZM17.6076 11.746C17.4726 11.7645 17.2654 11.7118 17.0569 11.5033L12.6662 7.1126C12.4577 6.90407 12.405 6.69691 12.4235 6.56186C12.5548 5.60149 12.9888 4.6761 13.7279 3.93699C15.5241 2.1408 18.4363 2.1408 20.2325 3.93699C22.0287 5.73318 22.0287 8.64539 20.2325 10.4416C19.4934 11.1807 18.568 11.6147 17.6076 11.746Z"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
