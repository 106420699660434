import type { SVGProps } from "react";

export const AlertTriangle = (props: SVGProps<SVGSVGElement>) => {
  const nt_fill = props.fill;
  return (
    <svg viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.9995 16.25V23.25M20.9995 30.25H21.017M18.0083 7.25365L3.18583 31.9986C2.88022 32.5279 2.71852 33.1279 2.71681 33.7391C2.7151 34.3502 2.87344 34.9511 3.17608 35.4821C3.47871 36.013 3.9151 36.4555 4.44182 36.7654C4.96854 37.0753 5.56723 37.2419 6.17833 37.2486H35.8233C36.4344 37.2419 37.0331 37.0753 37.5598 36.7654C38.0866 36.4555 38.5229 36.013 38.8256 35.4821C39.1282 34.9511 39.2866 34.3502 39.2849 33.7391C39.2831 33.1279 39.1214 32.5279 38.8158 31.9986L23.9933 7.25365C23.6814 6.73934 23.2421 6.31411 22.7179 6.019C22.1938 5.72388 21.6024 5.56885 21.0008 5.56885C20.3993 5.56885 19.8079 5.72388 19.2837 6.019C18.7596 6.31411 18.3203 6.73934 18.0083 7.25365Z"
        stroke={nt_fill}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
