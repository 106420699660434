import type { SVGProps } from "react";

export const ErrorRound = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.9987 1.83325C5.9387 1.83325 1.83203 5.93992 1.83203 10.9999C1.83203 16.0599 5.9387 20.1666 10.9987 20.1666C16.0587 20.1666 20.1654 16.0599 20.1654 10.9999C20.1654 5.93992 16.0587 1.83325 10.9987 1.83325ZM11.9154 15.5833H10.082V13.7499H11.9154V15.5833ZM11.9154 11.9166H10.082V6.41658H11.9154V11.9166Z"
        fill="inherit"
      />
    </svg>
  );
};
