import type { MouseEventHandler, SVGProps } from "react";

import styled from "styled-components";

import { PaletteKind, usePaletteColor } from "../../hooks/usePaletteColor";
import { ChevronLeft } from "./Icons/ChevronLeft";
import { MoreHoriz } from "./Icons/MoreHoriz";
import { Table } from "./Icons/Table";
import { Trash2 } from "./Icons/Trash2";
import { HelpRound } from "./Icons/HelpRound";
import { ErrorRound } from "./Icons/ErrorRound";
import { Close } from "./Icons/Close";
import { Users } from "./Icons/Users";
import { User } from "./Icons/User";
import { Opportunity } from "./Icons/Opportunity";
import { Check } from "./Icons/Check";
import { ExternalLink } from "./Icons/ExternalLink";
import { LinkRound } from "./Icons/LinkRound";
import { Mic2 } from "./Icons/Mic2";
import { ChevronRight } from "./Icons/ChevronRight";
import { ChevronBottom } from "./Icons/ChevronBottom";
import { AlertTriangle } from "./Icons/AlertTriangle";
import { Upload } from "./Icons/Upload";
import { Logout } from "./Icons/Logout";

export type IconName =
  | "alert-triangle"
  | "check"
  | "chevron-left"
  | "chevron-right"
  | "chevron-bottom"
  | "close"
  | "error-round"
  | "external-link"
  | "help-round"
  | "link-round"
  | "log-out"
  | "mic2"
  | "more-horiz"
  | "opportunity"
  | "trash2"
  | "table"
  | "upload"
  | "user"
  | "users";

export const isIconName = (value: string): value is IconName => {
  return (
    value === "alert-triangle" ||
    value === "check" ||
    value === "close" ||
    value === "chevron-left" ||
    value === "chevron-right" ||
    value === "chevron-bottom" ||
    value === "error-round" ||
    value === "external-link" ||
    value === "help-round" ||
    value === "link-round" ||
    value === "log-out" ||
    value === "mic2" ||
    value === "more-horiz" ||
    value === "opportunity" ||
    value === "trash2" ||
    value === "table" ||
    value === "upload" ||
    value === "user" ||
    value === "users"
  );
};

export type IconProps = Omit<SVGProps<SVGSVGElement>, "color" | "onClick"> & {
  name: IconName;
  size?: "14" | "16" | "18" | "20" | "24" | "28" | "32" | "36" | "42" | "48" | "60" | undefined;
  color?: PaletteKind | undefined;
  button?: boolean | undefined;
  buttonId?: string | undefined;
  value?: string | number | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
};

const getIconComponent = (name: IconName, props: SVGProps<SVGSVGElement>) => {
  switch (name) {
    case "alert-triangle":
      return <AlertTriangle {...props} />;
    case "chevron-left":
      return <ChevronLeft {...props} />;
    case "chevron-right":
      return <ChevronRight {...props} />;
    case "chevron-bottom":
      return <ChevronBottom {...props} />;
    case "trash2":
      return <Trash2 {...props} />;
    case "table":
      return <Table {...props} />;
    case "mic2":
      return <Mic2 {...props} />;
    case "more-horiz":
      return <MoreHoriz {...props} />;
    case "user":
      return <User fill="none" {...props} />;
    case "users":
      return <Users {...props} />;
    case "close":
      return <Close {...props} fill={props.stroke} />;
    case "help-round":
      return <HelpRound {...props} stroke={undefined} fill={props.stroke} color={undefined} />;
    case "link-round":
      return <LinkRound {...props} stroke={undefined} fill={props.stroke} color={undefined} />;
    case "log-out":
      return <Logout {...props} />;
    case "error-round":
      return <ErrorRound {...props} stroke={undefined} fill={props.stroke} color={undefined} />;
    case "opportunity":
      return <Opportunity {...props} stroke={undefined} fill={props.stroke} />;
    case "check":
      return <Check {...props} fill="none" />;
    case "external-link":
      return <ExternalLink {...props} fill="none" />;
    case "upload":
      return <Upload {...props} />;
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};

export function Icon({
  name,
  size,
  width,
  height,
  color,
  button,
  buttonId,
  onClick,
  value,
  ...rest
}: IconProps): JSX.Element {
  const w = size ?? width ?? 16;
  const h = size ?? height ?? 16;
  const svgProps: SVGProps<SVGSVGElement> = {
    ...rest,
    width: w,
    height: h,
  };

  svgProps.stroke = usePaletteColor(color, "currentColor");

  const IconComponent = getIconComponent(name, svgProps);

  if (button) {
    return (
      <Btn id={buttonId} onClick={onClick} value={value}>
        {IconComponent}
      </Btn>
    );
  } else {
    return IconComponent;
  }
}

const Btn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: fit-content;

  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  border: none;
  outline: none;

  background: transparent;

  &:focus,
  &:hover {
    cursor: pointer;
    background: ${(p) =>
      p.theme.mode === "dark"
        ? `linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))`
        : `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))`};
  }
`;
