import type { SVGProps } from "react";

export const Upload = (props: SVGProps<SVGSVGElement>) => {
  const nt_fill = props.fill;
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.5 13V16.3333C17.5 16.7754 17.3244 17.1993 17.0118 17.5118C16.6993 17.8244 16.2754 18 15.8333 18H4.16667C3.72464 18 3.30072 17.8244 2.98816 17.5118C2.67559 17.1993 2.5 16.7754 2.5 16.3333V13M14.1667 7.16667L10 3M10 3L5.83333 7.16667M10 3V13"
        stroke={nt_fill}
        stroke-opacity="0.6"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
