import type { SVGProps } from "react";

export const HelpRound = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.9987 1.33325C4.3187 1.33325 1.33203 4.31992 1.33203 7.99992C1.33203 11.6799 4.3187 14.6666 7.9987 14.6666C11.6787 14.6666 14.6654 11.6799 14.6654 7.99992C14.6654 4.31992 11.6787 1.33325 7.9987 1.33325ZM8.66536 12.6666H7.33203V11.3333H8.66536V12.6666ZM10.0454 7.49992L9.44536 8.11325C8.96536 8.59992 8.66536 8.99992 8.66536 9.99992H7.33203V9.66658C7.33203 8.93325 7.63203 8.26658 8.11203 7.77992L8.9387 6.93992C9.18536 6.69992 9.33203 6.36658 9.33203 5.99992C9.33203 5.26659 8.73203 4.66658 7.9987 4.66658C7.26536 4.66658 6.66536 5.26659 6.66536 5.99992H5.33203C5.33203 4.52659 6.52536 3.33325 7.9987 3.33325C9.47203 3.33325 10.6654 4.52659 10.6654 5.99992C10.6654 6.58659 10.4254 7.11992 10.0454 7.49992Z"
        fill="inherit"
        color="inherit"
      />
    </svg>
  );
};
