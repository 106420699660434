import { useTheme } from "styled-components";

export type PaletteKind = "error-main" | "primary-main" | "text-primary" | "text-secondary" | "warn-main";

export const usePaletteColor = (color?: string | undefined, defaultColor = "currentColor") => {
  const theme = useTheme();

  switch (color) {
    case "primary-main":
      return theme.vars.palette.primary.main;
    case "warn-main":
      return "rgba(242, 153, 74, 1)";
    case "error-main":
      return theme.vars.palette.error.main;
    case "text-primary":
      return theme.vars.palette.text.primary;
    case "text-secondary":
      return theme.vars.palette.text.secondary;
    default:
      return defaultColor;
  }
};
