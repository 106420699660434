import type { SVGProps } from "react";

export const Close = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.40156 13.6496L0.351562 12.5996L5.95156 6.99961L0.351562 1.39961L1.40156 0.349609L7.00156 5.94961L12.6016 0.349609L13.6516 1.39961L8.05156 6.99961L13.6516 12.5996L12.6016 13.6496L7.00156 8.04961L1.40156 13.6496Z"
        fill="inherit"
      />
    </svg>
  );
};
