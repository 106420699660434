import type { SVGProps } from "react";

export const Opportunity = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.0643 11.56H2.93631C2.69631 11.56 2.48031 11.776 2.48031 12.016V12.04C2.48031 12.832 3.12831 13.48 3.92031 13.48H12.0803C12.8723 13.48 13.5203 12.832 13.5203 12.04V12.016C13.5203 11.776 13.3043 11.56 13.0643 11.56Z"
        fill="inherit"
      />
      <path
        d="M14.2403 1.72004C13.4483 1.72004 12.8003 2.36804 12.8003 3.16004C12.8003 3.59204 12.9923 4.00004 13.3043 4.26404C12.8963 5.20004 11.9603 5.84804 10.8563 5.80004C9.58431 5.72804 8.55231 4.69604 8.48031 3.42404C8.48031 3.20804 8.48031 3.01604 8.52831 2.82404C9.05631 2.60804 9.44031 2.10404 9.44031 1.48004C9.44031 0.688039 8.79231 0.0400391 8.00031 0.0400391C7.20831 0.0400391 6.56031 0.688039 6.56031 1.48004C6.56031 2.08004 6.94431 2.60804 7.47231 2.82404C7.52031 3.01604 7.52031 3.20804 7.52031 3.42404C7.44831 4.69604 6.41631 5.72804 5.14431 5.80004C4.04031 5.87204 3.08031 5.20004 2.69631 4.26404C3.00831 4.00004 3.20031 3.59204 3.20031 3.16004C3.20031 2.36804 2.55231 1.72004 1.76031 1.72004C0.968313 1.72004 0.320312 2.36804 0.320312 3.16004C0.320312 3.95204 0.968313 4.60004 1.76031 4.60004L2.43231 9.73604C2.45631 9.95204 2.64831 10.12 2.88831 10.12H13.1123C13.3283 10.12 13.5203 9.95204 13.5683 9.73604L14.2403 4.60004C15.0323 4.60004 15.6803 3.95204 15.6803 3.16004C15.6803 2.36804 15.0323 1.72004 14.2403 1.72004Z"
        fill="inherit"
      />
    </svg>
  );
};
