import type { SVGProps } from "react";

export const ExternalLink = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 11.5V17.5C16 18.0304 15.7893 18.5391 15.4142 18.9142C15.0391 19.2893 14.5304 19.5 14 19.5H3C2.46957 19.5 1.96086 19.2893 1.58579 18.9142C1.21071 18.5391 1 18.0304 1 17.5V6.5C1 5.96957 1.21071 5.46086 1.58579 5.08579C1.96086 4.71071 2.46957 4.5 3 4.5H9M13 1.5H19M19 1.5V7.5M19 1.5L8 12.5"
        // stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
