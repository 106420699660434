import type { SVGProps } from "react";

export const Check = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 26 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.6663 1.5L8.99967 16.1667L2.33301 9.5"
        // stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
