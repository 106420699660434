import type { SVGProps } from "react";

export const MoreHoriz = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask height="24" id="mask0_886_10441" maskUnits="userSpaceOnUse" width="24" x="0" y="0">
        <rect fill="#D9D9D9" height="24" width="24" />
      </mask>
      <g mask="url(#mask0_886_10441)">
        <path
          d="M6.22656 13.5C5.8099 13.5 5.4559 13.354 5.16456 13.062C4.87256 12.7707 4.72656 12.4167 4.72656 12C4.72656 11.5833 4.87256 11.2293 5.16456 10.938C5.4559 10.646 5.8099 10.5 6.22656 10.5C6.64323 10.5 6.99756 10.646 7.28956 10.938C7.5809 11.2293 7.72656 11.5833 7.72656 12C7.72656 12.4167 7.5809 12.7707 7.28956 13.062C6.99756 13.354 6.64323 13.5 6.22656 13.5ZM12.0016 13.5C11.5849 13.5 11.2309 13.354 10.9396 13.062C10.6476 12.7707 10.5016 12.4167 10.5016 12C10.5016 11.5833 10.6476 11.2293 10.9396 10.938C11.2309 10.646 11.5849 10.5 12.0016 10.5C12.4182 10.5 12.7722 10.646 13.0636 10.938C13.3556 11.2293 13.5016 11.5833 13.5016 12C13.5016 12.4167 13.3556 12.7707 13.0636 13.062C12.7722 13.354 12.4182 13.5 12.0016 13.5ZM17.7766 13.5C17.3599 13.5 17.0059 13.354 16.7146 13.062C16.4226 12.7707 16.2766 12.4167 16.2766 12C16.2766 11.5833 16.4226 11.2293 16.7146 10.938C17.0059 10.646 17.3599 10.5 17.7766 10.5C18.1932 10.5 18.5476 10.646 18.8396 10.938C19.1309 11.2293 19.2766 11.5833 19.2766 12C19.2766 12.4167 19.1309 12.7707 18.8396 13.062C18.5476 13.354 18.1932 13.5 17.7766 13.5Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};
